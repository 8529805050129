/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import './About.css';

import profile from '../../assets/me.jpg';

const useStyles = makeStyles((theme) => ({
  main: {
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3em',
    marginBottom: "auto",
  },
}));

export const About = () => {
  const classes = useStyles();
  const greetings = "Hello!";
  const aboutme = `Hello, I'm thrilled to introduce myself!

  • I'm a full stack web developer specializing in the MERN stack, which includes MongoDB, Express, React, and Node.js. 
  • In addition to my love for coding, I'm also a musician, music instructor, and music producer.
  • Creating is my passion, and I enjoy exploring different avenues for creative expression, including audio engineering, video production and editing, photo editing, and graphic design using tools like Photoshop and Illustrator.
  
  I sincerely value collaboration and believe that adjusting my approach to align with the needs of the project or group is an important aspect of achieving success. I believe that combining my technical skills with my creative talents enables me to deliver unique and innovative solutions that cater to my clients' diverse needs.
  
  I'm always striving to learn and grow, and I'm excited to take on new challenges and collaborate with like-minded individuals. Thank you for visiting my website, and I hope you enjoy exploring my work.
                  `;

  return (
    <section id="About" className="aboutcontainer">
        <Container component="mainsection" className={classes.main} maxWidth="md">
          <div className="column-about">
            {/* left column: picture */}
            <div className="aboutpic">
              <div className="_img"
                  style={{ 
                  background: "url(" + profile + ")",
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  }}
              >
              </div>
            </div>
            {/* right column: intro message */}
            <div className="aboutmessage">
                <Typography component='h2' variant="h5">
                    {greetings}
                </Typography>
                <p className="aboutme">{aboutme}</p>
                <div href="#contact" className="contact-btn">
                  <i className="fas fa-terminal"></i>
                  <Typography>
                    <a href="#Contact" className="contactMe"> Contact Me! </a> 
                </Typography>
                </div>
              </div>

          </div>
        </Container>
    </section>
  );
};

export default About;